import React from "react"
import { Link } from "gatsby"
import { Container, Grid, Button, Hidden } from "@material-ui/core"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import VideoBg from "reactjs-videobg"

import Family from "../images/home/connect-with-us.jpg"
import Badge from "../images/home/mortgage-employer-badge-small.png"
import CardBG from "../images/home/customer-promise.jpg"
import Phone from "../images/home/phone.png"
// import mov from "../images/home/marathon-home-video.mov"
import mp4 from "../images/home/marathon-home-video.mp4"
import webm from "../images/home/marathon-home-video.webm"
import Poster from "../images/home/poster.png"

// import { ChartBar } from 'tabler-icons-react'
import { Search, Home, AntennaBars5 } from "tabler-icons-react"

export default function Index() {
  var homeCard = {
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${CardBG})`,
  }

  var PosterFrame = {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${Poster})`,
  }

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "10px",
  })

  return (
    <Layout>
      <SEO title="Home" />
      <div className="home-banner flex justify-center items-center flex-col">
        <div className="quick-link bg-orange absolute top-0"></div>
        <div className="banner-content white-text">
          <Hidden mdUp>
            <div style={PosterFrame} className="mobile-banner-bg" />
          </Hidden>
          <Hidden smDown>
            <VideoBg
              wrapperClass="home-video-wrapper"
              videoClass="home-video"
              poster={Poster}
            >
              <VideoBg.Source src={mp4} type="video/mp4" />
              <VideoBg.Source src={webm} type="video/webm" />
            </VideoBg>
          </Hidden>
          <Container className="z-40">
            <Grid container>
              <Grid item className="mx-auto text-center">
                <motion.div
                  className="fade-in"
                  ref={ref}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 1.6, duration: 1 }}
                >
                  <h1>
                    Going the distance{" "}
                    <Hidden mdUp>
                      <br />
                    </Hidden>
                    for you.
                  </h1>
                  <p className="w-4/5 mx-auto">
                    Building trusted partnerships with mortgage brokers and
                    home-buyers from coast to coast.
                  </p>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div className="home-blue blue-section">
        <Container>
          <motion.div
            className="fade-in"
            ref={ref}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ delay: 0.3, duration: 0.3 }}
          >
            <Grid container className="parallax">
              <Grid
                item
                md={8}
                sm={12}
                className="white-text parallax-text text-center mx-auto"
              >
                <img
                  className="mb-20"
                  src={Badge}
                  alt="MARATHON
MORTGAGE CORP."
                />
                <h4>Welcome to Marathon Mortgage</h4>
                <p className="w-4/5 mx-auto">
                  At Marathon, we offer competitive mortgage options in a
                  welcoming environment where our clients feel listened to.
                </p>
                <div className="welcome-columns mx-auto flex justify-between mt-40">
                  <div className="welcome-c1 c">
                    <span>
                      <AntennaBars5
                        size={48}
                        strokeWidth={1}
                        color={"#E2E8F0"}
                      />
                    </span>
                    <p>competitive</p>
                  </div>
                  <div className="welcome-c2 c">
                    <span>
                      <Home size={48} strokeWidth={1} color={"#E2E8F0"} />
                    </span>
                    <p>innovative</p>
                  </div>
                  <div className="welcome-c3 c">
                    <span>
                      <Search size={48} strokeWidth={1} color={"#E2E8F0"} />
                    </span>
                    <p>customer-focused</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      </div>
      <div className="connect">
        <Container>
          <Grid
            container
            className="flex items-center mb-40 text-center md:text-left"
          >
            <Grid item md={6} sm={12} className="py-40">
              <h6 className="blue-text">Connect with us</h6>
              <h3 className="orange-text">
                Straightforward, accessible lending.
              </h3>
              <p className="sm:w-3/5 md:w-full mx-auto">
                We are a national mortgage lender – serving independent
                home-buyers and professional mortgage brokers.
              </p>
              <p className="sm:w-3/5 md:w-full mx-auto">
                Unlike a lot of other mortgage providers, we have a dedicated
                customer service department – committed to providing an
                exceptional consumer experience.
              </p>
              <Link to="/contact" className="blue-button">
                <Button>got a question? ask us</Button>
              </Link>
            </Grid>
            <Grid item md={6} sm={12}>
              <img
                src={Family}
                alt="MARATHON
MORTGAGE CORP."
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="blue-section white-text relative">
        <Container>
          <Grid container>
            <Grid
              item
              md={6}
              sm={12}
              className="py-40 text-center md:text-left mx-auto"
            >
              <h3>
                Ready to figure things out? <br />
                Try our mortgage calculators.
              </h3>
              <Link to="/calculators#calculate">
                <Button>get started now</Button>
              </Link>
            </Grid>
            <Grid item md={6} sm={12} className="text-center md:text-left">
              <img
                src={Phone}
                className="md:absolute md:bottom-0"
                alt="MARATHON
MORTGAGE CORP."
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        <Grid container className="home-card bg-blue-500 my-40">
          <Grid item md={6} style={homeCard} className="image p-40">
            <p>&nbsp;</p>
          </Grid>
          <Grid
            item
            md={6}
            className="white-text text-center md:text-left p-20 md:p-40"
          >
            <h6 className="mb-5">Our customer service promise</h6>
            <h4 className="mb-5">We are here to help</h4>
            <p>
              Whether you are a first time home buyer, looking to refinance your
              home or you are a mortgage broker or investor, you’ll get
              efficient, high-quality service and competitive rates – every
              time.{" "}
            </p>
            <Link to="/about">
              <Button>learn more</Button>
            </Link>
          </Grid>
        </Grid>
        <Hidden smDown>
          <div className="calc-offset" />
        </Hidden>
      </Container>
    </Layout>
  )
}
